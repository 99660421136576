<template>
  <nav
    ref="navRef"
    :class="{ collapsed: !expanded, 'navbar-beta': isBeta }"
    class="navbar navbar-vertical fixed-left navbar-expand-lg navbar-dark"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
  >
    <b-container fluid>
      <b-navbar-toggle target="sidebarCollapse" />

      <RouterLink v-if="expanded" class="navbar-brand" :to="{ name: 'home' }">
        {{ setting('ui_name') }}

        <h3 class="grey mb-0">
          {{ setting('ui_system_name') }}
        </h3>
      </RouterLink>
      <div v-else class="mt-5" />

      <b-navbar-nav v-if="isTablet">
        <b-nav-item>
          <LanguagePopover v-if="setting('ui_menu_language') === true" />
        </b-nav-item>
      </b-navbar-nav>

      <b-collapse
        id="sidebarCollapse"
        ref="sidebarCollapse"
        class="sidebar-collapse"
      >
        <b-navbar-nav>
          <b-nav-item
            v-for="item in rootNavItems.slice(0, 1)"
            :id="`popover-target-${item.title.toLowerCase()}`"
            :key="item.title.toLowerCase()"
            v-can-any="item.permissions"
            :to="item.to"
            :active="item.active"
            :link-classes="{
              'd-flex': true,
            }"
            :class="{ minimized: !expanded }"
          >
            <Icon
              class="grey"
              :class="{ 'mr-3': expanded }"
              :icon="item.icon"
            />
            <span v-if="expanded">{{ item.title }}</span>
            <RouterLink
              v-if="expanded && item.counter && item.counter.value > 0"
              v-b-popover.hover.html.bottom="item.counter.popoverTitle"
              :to="item.counter.to"
              class="badge ml-auto"
              :class="item.counter.className"
            >
              {{ applyCompactNotation(item.counter.value) }}
            </RouterLink>
          </b-nav-item>
          <b-nav-item
            v-if="showInbox"
            :id="`popover-target-inbox`"
            :key="`inbox`"
            v-can-any="['inbox.personal.view', 'inbox.company.view']"
            :to="{ name: 'inbox.list' }"
            :active="isNavActive('inbox', $route.name)"
            :link-classes="{
              'd-flex': true,
            }"
            :class="{ minimized: !expanded }"
          >
            <Icon class="grey" :class="{ 'mr-3': expanded }" icon="inbox" />
            <span v-if="expanded">{{ __('menu.inbox') }}</span>
          </b-nav-item>
          <li
            id="popover-target-quotes"
            class="nav-item nowrap position-relative"
            :class="{ minimized: !expanded }"
            role="button"
            tabindex="0"
            style="overflow-anchor: none"
          >
            <RouterLink
              :to="{ name: 'quotes.list' }"
              class="nav-link"
              :class="{ active: quotesClassActiveState }"
            >
              <div class="d-flex justify-content-between align-items-center">
                <Icon
                  class="grey"
                  :class="{ 'mr-3': expanded }"
                  icon="file-alt"
                />
                <span v-if="expanded">{{ __('menu.quotes') }}</span>
              </div>
            </RouterLink>
            <div class="position-absolute quote-progress-link-div">
              <RouterLink
                v-if="expanded && quoteStateCount > 0"
                v-b-popover.hover.html.bottom="__('statuses.in-progress')"
                :to="{ name: 'quotes.list', params: { tab: 'in-progress' } }"
                class="badge badge-primary mt-n1"
              >
                {{ quoteStateCount }}
              </RouterLink>
              <Icon
                v-if="expanded"
                v-b-toggle="expanded ? 'quotes-collapse' : null"
                class="grey ml-2 cursor-pointer"
                :class="{ active: quotesClassActiveState }"
                icon="angle-down"
              />
            </div>
          </li>

          <b-collapse
            id="quotes-collapse"
            accordion="sidebar-accordion"
            tag="li"
          >
            <ul v-if="expanded" class="p-0">
              <b-nav-item
                v-for="(item, index) in quotesNavItems"
                :key="index"
                v-can-any="item.permissions"
                :to="item.to"
                :active="item.active"
              >
                <div class="nav-sub-item w-100">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div>
                      <Icon class="grey mr-3" :icon="item.icon" size="13" />
                      {{ item.title }}
                    </div>
                    <b-badge
                      v-if="item.counter && item.counter.value"
                      class="ml-3"
                      :variant="item.counter.variant"
                    >
                      {{ applyCompactNotation(item.counter.value) }}
                    </b-badge>
                  </div>
                </div>
              </b-nav-item>
            </ul>
          </b-collapse>

          <b-nav-item
            v-for="item in rootNavItems.slice(1, 4)"
            :id="`popover-target-${item.title.toLowerCase()}`"
            :key="item.title.toLowerCase()"
            v-can-any="item.permissions"
            :to="item.to"
            :active="item.active"
            :link-classes="{
              'd-flex': true,
            }"
            :class="{ minimized: !expanded }"
          >
            <Icon
              class="grey"
              :class="{ 'mr-3': expanded }"
              :icon="item.icon"
            />
            <span v-if="expanded">{{ item.title }}</span>
            <RouterLink
              v-if="expanded && item.counter && item.counter.value > 0"
              v-b-popover.hover.html.bottom="item.counter.popoverTitle"
              :to="item.counter.to"
              class="badge ml-auto"
              :class="item.counter.className"
            >
              {{ item.counter.value }}
            </RouterLink>
          </b-nav-item>

          <b-nav-item
            id="popover-target-finance"
            v-can-any="['invoices.view', 'payments.view', 'statements.view']"
            v-b-toggle="expanded ? 'finances-collapse' : null"
            class="nowrap"
            :link-classes="{
              'd-flex': true,
              'justify-content-between': expanded,
            }"
            :class="{ minimized: !expanded }"
            :active="financesClassActiveState"
          >
            <div class="d-flex justify-content-between align-items-center">
              <Icon class="grey" :class="{ 'mr-3': expanded }" icon="coins" />
              <span v-if="expanded">{{ __('menu.finances') }}</span>
            </div>

            <Icon v-if="expanded" class="grey ml-2" icon="angle-down" />
          </b-nav-item>

          <b-collapse
            id="finances-collapse"
            accordion="sidebar-accordion"
            tag="li"
          >
            <ul v-if="expanded" class="p-0">
              <b-nav-item
                v-for="(item, index) in financesNavItems"
                :key="index"
                v-can-any="item.permissions"
                :to="item.to"
                :active="item.active"
              >
                <div class="nav-sub-item">
                  <Icon class="grey mr-3" :icon="item.icon" size="13" />
                  {{ item.title }}
                </div>
              </b-nav-item>
            </ul>
          </b-collapse>

          <b-nav-item
            id="popover-target-underwriting"
            v-can-any="[
              'schemas.view',
              'binders.view',
              'workflows.view',
              'datasets.view',
              'endorsement-libraries.view',
              'endorsement-groups.view',
              'products.view',
            ]"
            v-b-toggle="expanded ? 'underwriting-collapse' : null"
            class="nowrap"
            :link-classes="{
              'd-flex': true,
              'justify-content-between': expanded,
            }"
            :class="{ minimized: !expanded }"
            :active="underwritingClassActiveState"
          >
            <div class="d-flex justify-content-between align-items-center">
              <Icon
                class="grey"
                :class="{ 'mr-3': expanded }"
                icon="edit-alt"
              />
              <span v-if="expanded">{{ __('menu.underwriting') }}</span>
            </div>

            <Icon v-if="expanded" class="grey ml-2" icon="angle-down" />
          </b-nav-item>

          <b-collapse
            id="underwriting-collapse"
            accordion="sidebar-accordion"
            tag="li"
          >
            <ul v-if="expanded" class="p-0">
              <b-nav-item
                v-for="(item, index) in underwritingNavItems"
                :key="index"
                v-can-any="item.permissions"
                :to="item.to"
                :active="item.active"
              >
                <div class="nav-sub-item">
                  <Icon class="grey mr-3" :icon="item.icon" size="13" />
                  {{ item.title }}
                </div>
              </b-nav-item>
            </ul>
          </b-collapse>

          <b-nav-item
            id="popover-target-admin"
            v-can-any="[
              'entities.view',
              'users.view',
              'roles.view',
              'permissions.view',
              'quotes.object-groups.view',
              'quotes.document-templates.view',
              'quotes.scheduled-tasks.view',
              'quotes.email-templates.view',
              'quotes.document-types.view',
              'quotes.translation-libraries.view',
              'quotes.counters.view',
            ]"
            v-b-toggle="expanded ? 'admin-collapse' : null"
            class="nowrap"
            :link-classes="{
              'd-flex': true,
              'justify-content-between': expanded,
            }"
            :class="{ minimized: !expanded }"
            :active="adminClassActiveState"
          >
            <div class="d-flex justify-content-between align-items-center">
              <Icon class="grey" :class="{ 'mr-3': expanded }" icon="setting" />
              <span v-if="expanded">{{ __('menu.admin') }}</span>
            </div>

            <Icon v-if="expanded" class="grey ml-2" icon="angle-down" />
          </b-nav-item>

          <b-collapse
            id="admin-collapse"
            accordion="sidebar-accordion"
            tag="li"
          >
            <ul v-if="expanded" class="p-0">
              <b-nav-item
                v-for="(item, index) in adminNavItems"
                :key="index"
                v-can-any="item.permissions"
                :to="item.to"
                :active="item.active"
              >
                <div class="nav-sub-item">
                  <Icon class="grey mr-3" :icon="item.icon" size="13" />
                  {{ item.title }}
                </div>
              </b-nav-item>
            </ul>
          </b-collapse>

          <b-nav-item
            id="popover-target-data-exchange"
            v-can-any="['imports.view', 'exports.data']"
            v-b-toggle="expanded ? 'data-exchange-collapse' : null"
            class="nowrap"
            :link-classes="{
              'd-flex': true,
              'justify-content-between': expanded,
            }"
            :class="{ minimized: !expanded }"
            :active="dataExchangeClassActiveState"
          >
            <div class="d-flex justify-content-between align-items-center">
              <Icon
                class="grey"
                :class="{ 'mr-3': expanded }"
                icon="database"
              />
              <span v-if="expanded">{{ __('menu.data-exchange') }}</span>
            </div>

            <Icon v-if="expanded" class="grey ml-2" icon="angle-down" />
          </b-nav-item>

          <b-collapse
            id="data-exchange-collapse"
            accordion="sidebar-accordion"
            tag="li"
          >
            <ul v-if="expanded" class="p-0">
              <b-nav-item
                v-for="(item, index) in dataExchangeNavItems"
                :key="index"
                v-can-any="item.permissions"
                :to="item.to"
                :active="item.active"
              >
                <div class="nav-sub-item">
                  <Icon class="grey mr-3" :icon="item.icon" size="13" />
                  {{ item.title }}
                </div>
              </b-nav-item>
            </ul>
          </b-collapse>

          <b-nav-item
            id="popover-target-reports-and-analytics"
            v-can-any="['reports.view', 'data-models.view']"
            v-b-toggle="expanded ? 'reports-and-analytics-collapse' : null"
            class="nowrap"
            :link-classes="{
              'd-flex': true,
              'justify-content-between': expanded,
            }"
            :class="{ minimized: !expanded }"
            :active="reportsAndAnalyticsClassActiveState"
          >
            <div class="d-flex justify-content-between align-items-center">
              <Icon
                class="grey"
                :class="{ 'mr-3': expanded }"
                icon="chart-bar"
              />
              <span v-if="expanded">{{ __('menu.reports-analytics') }} </span>
            </div>

            <Icon v-if="expanded" class="grey ml-2" icon="angle-down" />
          </b-nav-item>

          <b-collapse
            id="reports-and-analytics-collapse"
            accordion="sidebar-accordion"
            tag="li"
          >
            <ul v-if="expanded" class="p-0">
              <b-nav-item
                v-for="(item, index) in reportsAndAnalyticsNavItems"
                :key="index"
                v-can-any="item.permissions"
                :to="item.to"
                :active="item.active"
              >
                <div class="nav-sub-item">
                  <Icon class="grey mr-3" :icon="item.icon" size="13" />
                  {{ item.title }}
                </div>
              </b-nav-item>
            </ul>
          </b-collapse>
        </b-navbar-nav>
      </b-collapse>

      <b-popover
        v-for="item in rootNavItems"
        :key="item.title.toLowerCase()"
        :target="`popover-target-${item.title.toLowerCase()}`"
        triggers="hover"
        placement="right"
        :custom-class="popoverClass"
      >
        <b-navbar-nav v-if="!expanded">
          <b-nav-item
            v-can-any="item.permissions"
            :to="item.to"
            :active="item.active"
            :link-classes="{
              'active-indicator-none': true,
            }"
          >
            <div>
              <Icon class="grey mr-2" size="13" :icon="item.icon" />
              {{ item.title }}
            </div>
          </b-nav-item>
        </b-navbar-nav>
      </b-popover>

      <b-popover
        v-if="!expanded"
        target="popover-target-quotes"
        triggers="hover"
        placement="right"
        :custom-class="popoverClass"
      >
        <b-navbar-nav v-if="!expanded">
          <b-nav-item :active="quotesClassActiveState" disabled>
            <span class="font-weight-bold">{{ __('menu.quotes') }}</span>
          </b-nav-item>
          <b-nav-item
            v-for="(item, index) in quotesNavItems"
            :key="index"
            v-can-any="item.permissions"
            :to="item.to"
            :active="item.active"
          >
            <div>
              <Icon class="grey mr-3" :icon="item.icon" size="13" />
              {{ item.title }}
            </div>
          </b-nav-item>
        </b-navbar-nav>
      </b-popover>

      <b-popover
        v-if="!expanded"
        target="popover-target-finance"
        triggers="hover"
        placement="right"
        :custom-class="popoverClass"
      >
        <b-navbar-nav v-if="!expanded">
          <b-nav-item :active="financesClassActiveState" disabled>
            <span class="font-weight-bold">{{ __('menu.finances') }}</span>
          </b-nav-item>
          <b-nav-item
            v-for="(item, index) in financesNavItems"
            :key="index"
            v-can-any="item.permissions"
            :to="item.to"
            :active="item.active"
          >
            <div>
              <Icon class="grey mr-3" :icon="item.icon" size="13" />
              {{ item.title }}
            </div>
          </b-nav-item>
        </b-navbar-nav>
      </b-popover>

      <b-popover
        v-if="!expanded"
        target="popover-target-underwriting"
        triggers="hover"
        placement="right"
        :custom-class="popoverClass"
      >
        <b-navbar-nav v-if="!expanded">
          <b-nav-item :active="underwritingClassActiveState" disabled>
            <span class="font-weight-bold">{{ __('menu.underwriting') }}</span>
          </b-nav-item>
          <b-nav-item
            v-for="(item, index) in underwritingNavItems"
            :key="index"
            v-can-any="item.permissions"
            :to="item.to"
            :active="item.active"
          >
            <div>
              <Icon class="grey mr-3" :icon="item.icon" size="13" />
              {{ item.title }}
            </div>
          </b-nav-item>
        </b-navbar-nav>
      </b-popover>

      <b-popover
        v-if="!expanded"
        target="popover-target-admin"
        triggers="hover"
        placement="right"
        :custom-class="popoverClass"
      >
        <b-navbar-nav v-if="!expanded">
          <b-nav-item :active="adminClassActiveState" disabled>
            <span class="font-weight-bold">{{ __('menu.admin') }}</span>
          </b-nav-item>
          <b-nav-item
            v-for="(item, index) in adminNavItems"
            :key="index"
            v-can-any="item.permissions"
            :to="item.to"
            :active="item.active"
          >
            <div>
              <Icon class="grey mr-3" :icon="item.icon" size="13" />
              {{ item.title }}
            </div>
          </b-nav-item>
        </b-navbar-nav>
      </b-popover>

      <b-popover
        v-if="!expanded"
        target="popover-target-data-exchange"
        triggers="hover"
        placement="right"
        :custom-class="popoverClass"
      >
        <b-navbar-nav v-if="!expanded">
          <b-nav-item :active="dataExchangeClassActiveState" disabled>
            <span class="font-weight-bold">{{ __('menu.data-exchange') }}</span>
          </b-nav-item>
          <b-nav-item
            v-for="(item, index) in dataExchangeNavItems"
            :key="index"
            v-can-any="item.permissions"
            :to="item.to"
            :active="item.active"
          >
            <div>
              <Icon class="grey mr-3" :icon="item.icon" size="13" />
              {{ item.title }}
            </div>
          </b-nav-item>
        </b-navbar-nav>
      </b-popover>

      <b-popover
        v-if="!expanded"
        target="popover-target-reports-and-analytics"
        triggers="hover"
        placement="right"
        :custom-class="popoverClass"
      >
        <b-navbar-nav v-if="!expanded">
          <b-nav-item :active="reportsAndAnalyticsClassActiveState" disabled>
            <span class="font-weight-bold">{{
              __('menu.reports-analytics')
            }}</span>
          </b-nav-item>
          <b-nav-item
            v-for="(item, index) in reportsAndAnalyticsNavItems"
            :key="index"
            v-can-any="item.permissions"
            :to="item.to"
            :active="item.active"
          >
            <div>
              <Icon class="grey mr-3" :icon="item.icon" size="13" />
              {{ item.title }}
            </div>
          </b-nav-item>
        </b-navbar-nav>
      </b-popover>

      <div v-if="!isTablet" class="text-center grey d-none d-lg-block">
        <b-row no-gutters align-content="center" align-h="center">
          <b-col cols="12" class="mb-2">
            <LanguagePopover v-if="setting('ui_menu_language') === true" />
          </b-col>
          <b-col cols="auto" class="d-flex mb-3">
            <RouterLink to="/release-notes" class="nav-item-link">
              <small>v.{{ version }}</small>
            </RouterLink>
            <span class="nav-item-link-separator ml-2">|</span>
            <RouterLink
              v-can:videos.view
              to="/videos"
              class="nav-item-link ml-2"
              :active="isNavActive('videos.', $route.name)"
            >
              {{ tc('menu.help-video', 2) }}
            </RouterLink>
            <span class="nav-item-link-separator ml-2">|</span>
            <a
              class="nav-item-link ml-2"
              target="_blank"
              :href="getDocumentationHref()"
            >
              API
            </a>
          </b-col>
          <b-col v-if="isBeta" cols="12">
            <b-badge :v-if="isBeta" pill variant="success"> BETA </b-badge>
          </b-col>
          <b-col cols="12">
            <small>by Insly Ltd</small>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </nav>
</template>

<script>
import {
  GET_QUOTE_STATE_COUNTER_ACTION,
  GET_QUOTE_TYPE_COUNTER_ACTION,
  QUOTE_STATE_COUNTER_GETTER,
  QUOTE_TYPE_COUNTER_GETTER,
} from '@/Modules/Base/BaseModule.js'
import { TYPE as QUOTE_TYPE } from '@/Modules/Quote/QuoteModel.js'
import { AUTH_USER_GETTER } from '@/Modules/Auth/AuthModule.js'
import { mapActions, mapGetters } from 'vuex'
import PerfectScrollbar from 'perfect-scrollbar'
import LanguagePopover from '@/Modules/Base/Components/LanguagePopover.vue'
import { debounce } from 'lodash'
import { applyCompactNotation } from '@/General/applyNumberFormatting.js'
import { version } from '@/initiateApp.js'
import { UserEmailAccountStatus } from '@/Modules/User/UserEmailAccount/UserEmailAccountModel.js'
import { UserEmailAccountTypes } from '@/Modules/User/UserEmailAccount/UserEmailAccountModel.js'

export default {
  name: 'CoverholderNavigation',
  components: { LanguagePopover },
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
    onMouseOver: {
      type: Function,
      default: () => {},
    },
    onMouseOut: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      version,
      isBeta: import.meta.env.DEV,
      nodeEnv: process.env.NODE_ENV,
      ps: null,
      windowWidth: 0,
    }
  },

  computed: {
    ...mapGetters('BaseModule', {
      quoteStateCount: QUOTE_STATE_COUNTER_GETTER,
      quoteTypeCounter: QUOTE_TYPE_COUNTER_GETTER,
    }),

    ...mapGetters('AuthModule', {
      user: AUTH_USER_GETTER,
    }),

    popoverClass() {
      return `navigation-popover navbar-dark ${
        this.isBeta ? 'navbar-beta' : ''
      }`
    },

    showInbox() {
      return (
        this.user.emailAccounts?.filter(
          (account) =>
            account.type === UserEmailAccountTypes.INCOMING &&
            account.status === UserEmailAccountStatus.ACTIVE
        ).length > 0
      )
    },

    isTablet() {
      const media = '(max-width: 992px)'
      return this.windowWidth && window.matchMedia(media).matches
    },

    rootNavItems() {
      return [
        {
          permissions: null,
          to: {
            name: 'home',
          },
          icon: 'estate',
          title: this.__('menu.home'),
          active: this.isNavActive('home', this.$route.name),
        },
        {
          permissions: ['policies.view'],
          to: {
            name: 'policies.list',
          },
          icon: 'umbrella',
          title: this.__('menu.policies'),
          active: this.isNavActive('policies.', this.$route.name),
        },
        {
          permissions: [
            'renewals.list.accepted',
            'renewals.list.ending-soon',
            'renewals.list.process',
            'renewals.list.overdue',
            'renewals.list.expired',
            'renewals.list.successful',
          ],
          to: {
            name: 'renewals.list',
          },
          icon: 'share',
          title: this.__('menu.policies-renewals'),
          active: this.isNavActive('renewals.', this.$route.name),
        },
        {
          permissions: ['clients.view'],
          to: {
            name: 'clients.list',
          },
          icon: 'bag',
          title: this.__('menu.clients'),
          active: this.isNavActive('clients.', this.$route.name),
        },
      ]
    },

    quotesNavItems() {
      return [
        {
          permissions: ['quotes.view'],
          to: {
            name: 'quotes.list',
            query: {
              type: QUOTE_TYPE.NEW,
            },
          },
          icon: 'file-alt',
          title: this.__('quotes.only-new'),
          active: this.$route.query.type === QUOTE_TYPE.NEW,
          counter: {
            value: this.quoteTypeCounter?.[QUOTE_TYPE.NEW],
          },
        },
        {
          permissions: ['quotes.view'],
          to: {
            name: 'quotes.list',
            query: {
              type: QUOTE_TYPE.RENEWAL,
            },
          },
          icon: 'file',
          title: this.__('quotes.only-renewal'),
          active: this.$route.query.type === QUOTE_TYPE.RENEWAL,
          counter: {
            value: this.quoteTypeCounter?.[QUOTE_TYPE.RENEWAL],
          },
        },
        {
          permissions: ['quotes.view'],
          to: {
            name: 'quotes.list',
            query: {
              type: QUOTE_TYPE.ADJUSTMENT,
            },
          },
          icon: 'file-plus',
          title: this.__('quotes.only-adjustment'),
          active: this.$route.query.type === QUOTE_TYPE.ADJUSTMENT,
          counter: {
            value: this.quoteTypeCounter?.[QUOTE_TYPE.ADJUSTMENT],
          },
        },
        {
          permissions: ['quotes.view'],
          to: {
            name: 'quotes.list',
            query: {
              type: QUOTE_TYPE.TERMINATION,
            },
          },
          icon: 'file-minus',
          title: this.__('quotes.only-termination'),
          active: this.$route.query.type === QUOTE_TYPE.TERMINATION,
          counter: {
            value: this.quoteTypeCounter?.[QUOTE_TYPE.TERMINATION],
          },
        },
      ]
    },

    financesNavItems() {
      return [
        {
          permissions: ['invoices.view'],
          to: {
            name: 'invoices.list',
          },
          icon: 'invoice',
          title: this.__('menu.invoices'),
          active: this.isNavActive('invoices.', this.$route.name),
        },
        {
          permissions: ['payments.view'],
          to: {
            name: 'payments.list',
          },
          icon: 'transaction',
          title: this.__('menu.payments'),
          active: this.isNavActive('payments.', this.$route.name),
        },
        {
          permissions: ['statements.view'],
          to: {
            name: 'statements.agent-list',
            params: {
              tab: 'all',
            },
          },
          icon: 'newspaper',
          title: this.__('menu.agent-statements'),
          active: this.isNavActive('statements.agent', this.$route.name),
        },
        {
          permissions: ['statements.view'],
          to: {
            name: 'statements.insurer-list',
            params: {
              tab: 'all',
            },
          },
          icon: 'newspaper',
          title: this.__('menu.insurer-statements'),
          active: this.isNavActive('statements.insurer', this.$route.name),
        },
        {
          permissions: ['payment-accounts.view'],
          to: {
            name: 'payment-accounts.list',
          },
          icon: 'university',
          title: this.__('payment-accounts.payment-accounts'),
          active: this.isNavActive('payment-accounts.', this.$route.name),
        },
        {
          permissions: ['payment-plans.view'],
          to: {
            name: 'payment-plans.list',
          },
          icon: 'credit-card',
          title: this.__('payment-plans.pre-title'),
          active: this.isNavActive('payment-plans.', this.$route.name),
        },
        {
          permissions: ['transaction-categories.view'],
          to: {
            name: 'transaction-categories.list',
          },
          icon: 'file-plus-alt',
          title: this.__('menu.transaction-categories'),
          active: this.isNavActive('transaction-categories.', this.$route.name),
        },
        {
          permissions: ['currencies.view'],
          to: {
            name: 'currencies.list',
          },
          icon: 'usd-circle',
          title: this.__('menu.currencies'),
          active: this.isNavActive('currencies.', this.$route.name),
        },
        {
          permissions: ['payment-terms.view'],
          to: {
            name: 'payment-terms.list',
          },
          icon: 'wallet',
          title: this.__('menu.payment-terms'),
          active: this.isNavActive('payment-terms.', this.$route.name),
        },
      ]
    },

    underwritingNavItems() {
      return [
        {
          permissions: ['schemas.view'],
          to: {
            name: 'schemas.list',
          },
          icon: 'web-grid',
          title: this.__('menu.schemas'),
          active: this.isNavActive('schemas.', this.$route.name),
        },
        {
          permissions: ['binders.view'],
          to: {
            name: 'binders.list',
          },
          icon: 'book-open',
          title: this.__('menu.binders'),
          active: this.isNavActive('binders.', this.$route.name),
        },
        {
          permissions: ['workflows.view'],
          to: {
            name: 'workflows.list',
          },
          icon: 'arrows-left-down',
          title: this.__('menu.workflows'),
          active: this.isNavActive('workflows.', this.$route.name),
        },
        {
          permissions: ['datasets.view'],
          to: {
            name: 'datasets.list',
          },
          icon: 'list-ul',
          title: this.__('menu.datasets'),
          active: this.isNavActive('datasets.', this.$route.name),
        },
        {
          permissions: ['endorsement-libraries.view'],
          to: {
            name: 'endorsement-libraries.list',
          },
          icon: 'archive',
          title: this.__('menu.endorsements'),
          active: this.isNavActive('endorsement-libraries.', this.$route.name),
        },
        {
          permissions: ['endorsement-groups.view'],
          to: {
            name: 'endorsement-groups.list',
          },
          icon: 'dialpad',
          title: this.__('menu.endorsement-groups'),
          active: this.isNavActive('endorsement-groups.', this.$route.name),
        },
        {
          permissions: ['products.view'],
          to: {
            name: 'products.list',
          },
          icon: 'box',
          title: this.__('menu.products'),
          active: this.isNavActive('products.', this.$route.name),
        },
        {
          permissions: ['integrations.view'],
          to: {
            name: 'integrations.list',
          },
          icon: 'data-sharing',
          title: this.__('menu.integrations'),
          active: this.isNavActive('integrations.', this.$route.name),
        },
      ]
    },

    adminNavItems() {
      const items = [
        {
          permissions: ['entities.view'],
          to: {
            name: 'entities.list',
          },
          icon: 'briefcase',
          title: this.__('menu.entities'),
          active: this.isNavActive('entities.', this.$route.name),
        },
        {
          permissions: ['users.view'],
          to: {
            name: 'users.list',
          },
          icon: 'user',
          title: this.__('menu.users'),
          active: this.isNavActive('users.', this.$route.name),
        },
        {
          permissions: ['roles.view'],
          to: {
            name: 'roles.list',
          },
          icon: 'shield',
          title: this.__('menu.roles'),
          active: this.isNavActive('roles.', this.$route.name),
        },
        {
          permissions: ['permissions.view'],
          to: {
            name: 'permissions.list',
          },
          icon: 'tag',
          title: this.__('menu.permissions'),
          active: this.isNavActive('permissions.', this.$route.name),
        },

        {
          permissions: ['document-types.view'],
          to: {
            name: 'document-types.list',
          },
          icon: 'document-info',
          title: this.__('menu.document-types'),
          active: this.isNavActive('document-types.', this.$route.name),
        },
        {
          permissions: ['document-templates.view'],
          to: {
            name: 'document-templates.list',
          },
          icon: 'file-plus-alt',
          title: this.__('menu.document-templates'),
          active: this.isNavActive('document-templates.', this.$route.name),
        },
        {
          permissions: ['email-templates.view'],
          to: {
            name: 'email-templates.list',
          },
          icon: 'envelope',
          title: this.__('menu.email-templates'),
          active:
            this.isNavActive('email-templates.', this.$route.name) ||
            this.isNavActive('email-parts.', this.$route.name),
        },
        {
          permissions: ['email-accounts.view'],
          to: {
            name: 'email-accounts.list',
          },
          icon: 'envelope-share',
          title: this.__('menu.email-accounts'),
          active: this.isNavActive('email-accounts.', this.$route.name),
        },
        {
          permissions: ['quotes.object-groups.view'],
          to: {
            name: 'quote-object-groups.list',
          },
          icon: 'bookmark',
          title: this.__('menu.object-groups'),
          active: this.isNavActive('quote-object-groups.', this.$route.name),
        },
        {
          permissions: ['scheduled-tasks.view'],
          to: {
            name: 'scheduled-tasks.list',
          },
          icon: 'schedule',
          title: this.__('menu.scheduled-tasks'),
          active: this.isNavActive('scheduled-tasks.', this.$route.name),
        },

        {
          permissions: ['translation-libraries.view'],
          to: {
            name: 'translation-libraries.list',
          },
          icon: 'language',
          title: this.__('menu.translation-libraries'),
          active: this.isNavActive('translation-libraries.', this.$route.name),
        },
        {
          permissions: ['counters.view'],
          to: {
            name: 'counters.list',
          },
          icon: 'list-ol',
          title: this.__('menu.counters'),
          active: this.isNavActive('counters.', this.$route.name),
        },
        {
          permissions: ['countries.view'],
          to: {
            name: 'countries.list',
          },
          icon: 'globe',
          title: this.__('menu.countries'),
          active: this.isNavActive('countries.', this.$route.name),
        },
        {
          permissions: null,
          to: {
            name: 'settings.list',
          },
          icon: 'setting',
          title: this.__('menu.settings'),
          isSupervisor: true,
          active: this.isNavActive('settings.', this.$route.name),
        },
      ].filter((item) => (item.isSupervisor ? this.user?.isSupervisor : true))

      if (
        this.setting('ui_custom_css_enabled') === true &&
        this.user?.isSupervisor
      ) {
        items.push({
          permissions: null,
          to: {
            name: 'custom-css.show',
          },
          icon: 'css3-simple',
          title: this.__('menu.custom-css'),
          active: this.isNavActive('custom-css.', this.$route.name),
        })
      }

      return items
    },

    dataExchangeNavItems() {
      return [
        {
          permissions: ['exports.data'],
          to: {
            name: 'export-import.export',
          },
          icon: 'file-export',
          title: this.__('menu.export-data'),
          active: this.isNavActive('export-import.export', this.$route.name),
        },
        {
          permissions: ['imports.preload'],
          to: {
            name: 'export-import.preload',
          },
          icon: 'file-import',
          title: this.__('menu.import-data'),
          active: this.isNavActive(
            ['export-import.preload', 'export-import.import'],
            this.$route.name
          ),
        },
      ]
    },

    reportsAndAnalyticsNavItems() {
      return [
        {
          permissions: ['data-models.view'],
          to: {
            name: 'data-models.list',
          },
          icon: 'database',
          title: this.__('menu.data-models'),
          active: this.isNavActive('data-models.', this.$route.name),
        },
        {
          permissions: ['reports.view'],
          to: {
            name: 'reports.list',
          },
          icon: 'chart-bar',
          title: this.__('menu.reports'),
          active: this.isNavActive('reports.', this.$route.name),
        },
        {
          permissions: ['quotes.logs.integration.all'],
          to: {
            name: 'api-logs.list',
          },
          icon: 'caret-right',
          title: this.__('menu.api-logs'),
          active: this.isNavActive('api-logs.', this.$route.name),
        },
      ]
    },

    adminClassActiveState: function () {
      if (!this.$route.name) return false

      return (
        this.$route.name.includes('entities.') ||
        this.$route.name.includes('users.') ||
        this.$route.name.includes('roles.') ||
        this.$route.name.includes('permissions.') ||
        this.$route.name.includes('quote-object-groups.') ||
        this.$route.name.includes('email-templates.') ||
        this.$route.name.includes('email-accounts.') ||
        this.$route.name.includes('document-types.') ||
        this.$route.name.includes('document-templates.') ||
        this.$route.name.includes('scheduled-tasks.') ||
        this.$route.name.startsWith('counters.') ||
        this.$route.name.includes('settings.') ||
        this.$route.name.includes('translation-libraries.') ||
        this.$route.name.includes('countries.') ||
        this.$route.name.includes('email-parts.') ||
        this.$route.name.includes('custom-css.')
      )
    },

    underwritingClassActiveState: function () {
      if (!this.$route.name) return false

      return (
        this.$route.name.startsWith('schemas.') ||
        this.$route.name.startsWith('binders.') ||
        this.$route.name.startsWith('workflows.') ||
        this.$route.name.startsWith('datasets.') ||
        this.$route.name.startsWith('endorsement-libraries.') ||
        this.$route.name.startsWith('endorsement-groups.') ||
        this.$route.name.startsWith('products.') ||
        this.$route.name.startsWith('integrations.')
      )
    },

    financesClassActiveState: function () {
      if (!this.$route.name) return false

      return (
        this.$route.name.startsWith('invoices.') ||
        this.$route.name.startsWith('payments.') ||
        this.$route.name.includes('transaction-categories.') ||
        this.$route.name.includes('statements.') ||
        this.$route.name.includes('payment-accounts.') ||
        this.$route.name.includes('payment-plans.') ||
        this.$route.name.includes('currencies.') ||
        this.$route.name.includes('payment-terms.')
      )
    },

    dataExchangeClassActiveState: function () {
      if (!this.$route.name) return false

      return this.$route.name.includes('export-import.')
    },

    reportsAndAnalyticsClassActiveState: function () {
      if (!this.$route.name) return false

      return (
        this.$route.name.startsWith('data-models.') ||
        this.$route.name.startsWith('reports.') ||
        this.$route.name.startsWith('api-logs.')
      )
    },

    quotesClassActiveState: function () {
      if (!this.$route.name) return false

      return this.$route.name.startsWith('quotes.')
    },
  },

  watch: {
    '$route.name': {
      handler() {
        this.$root.$emit('bv::hide::popover')
      },
    },

    isTablet: {
      handler(newValue) {
        this.$emit('resize', { isTablet: newValue })
      },
    },
  },

  created() {
    this.getQuoteStateCounter('inProgress')
    this.getQuoteTypeCounter()
    if (process.env.NODE_ENV !== 'production') {
      this.isBeta = true
    }
    this.handleWindowResize = debounce((event) => {
      this.windowWidth = event.target.innerWidth
    }, 300)
  },

  mounted() {
    if (this.$refs.navRef) {
      this.ps = new PerfectScrollbar(this.$refs.navRef)
    }
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', this.handleWindowResize)
  },

  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },

  methods: {
    ...mapActions('BaseModule', {
      getQuoteStateCounter: GET_QUOTE_STATE_COUNTER_ACTION,
      getQuoteTypeCounter: GET_QUOTE_TYPE_COUNTER_ACTION,
    }),

    getDocumentationHref() {
      const pathname = '/api/documentation'
      const origin = window?.location.origin
      return origin + pathname
    },

    isNavActive(name, routeName) {
      if (!routeName || !name) {
        return false
      }

      const names = _.isArray(name) ? name : [name]

      return names.filter((item) => routeName.startsWith(item)).length > 0
    },

    applyCompactNotation(value) {
      return applyCompactNotation(value)
    },
  },
}
</script>

<style lang="scss">
.navbar {
  .nav-item-link {
    color: #6e84a3;

    &:hover,
    &:active,
    &:focus,
    &.router-link-active {
      color: #fff;
    }
  }
}

.nav-sub-item {
  margin-left: 15px;
  font-size: 14px;
}

.sidebar-collapse {
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;

  flex-basis: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  align-items: center;
}

.navbar-dark .sidebar-collapse::before {
  border-top-color: #1e3a5c;
}

.sidebar-collapse:before {
  content: '';
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0.75rem -1rem;
}

.navbar-dark {
  &.navigation-popover {
    background-color: #1e3a5c;
    .arrow {
      &::after {
        border-right-color: #1e3a5c;
      }
    }
  }
}

@media (min-width: 992px) {
  .navbar-vertical {
    transition: all 0.4s;
    transform: translateX(0);

    .navbar-nav {
      .nav-item {
        transition: transform 0.3s;
        transform: translateX(0);

        .nav-link {
          &.active-indicator-none {
            &::before {
              display: none;
            }
          }
        }

        &.minimized {
          width: 45px;
          transform: translateX(205px);

          .nav-link {
            padding-left: 15px;
          }
        }
      }
    }
  }

  .navbar-vertical.collapsed {
    transform: translateX(-205px);
  }

  .navbar-vertical.navbar-expand-lg {
    overflow: hidden;
  }

  .navbar-vertical.navbar-expand-lg .sidebar-collapse {
    -webkit-box-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .navbar-vertical.navbar-expand-lg .navbar-brand {
    display: block;
    text-align: center;
    padding-top: 0.55rem;
    padding-bottom: 0.5rem;
  }

  .navbar-expand-lg .sidebar-collapse {
    -webkit-box-flex: inherit;
    flex-grow: inherit;
  }

  .navbar-brand {
    margin-bottom: 0.84375rem;
  }
}

.grey {
  color: #6e84a3;
}

a:hover,
a.active {
  i {
    color: white;
  }
}

.navbar-brand {
  h3 {
    font-weight: 400;
    text-align: center;
  }
}

.navbar-dark .navbar-nav {
  .nav-link {
    &.disabled {
      color: #6e84a3;
    }
    &.active {
      color: white;
    }
  }
}
.quote-progress-link-div {
  right: 24px;
  top: 8px;

  i {
    &.active,
    &:hover {
      color: white;
    }
  }
}

.navbar-beta {
  background-color: #194c53 !important;
}
</style>
